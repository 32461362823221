import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Clipboard from 'react-clipboard.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CableIcon from '@mui/icons-material/Cable';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Section from 'components/Section';
import { TableContainer, Table, TableHead, TableRow, TableCell, CardContent, TableBody,TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import PdfDialog from 'components/PdfDialog';
import { teal, penly } from 'api';
import { config } from 'utils/config';
import { renderDateLong, renderTime, renderKr, capitalizeFirstLetter } from 'utils/formatter';
import { statusOptions } from 'utils/constants';
import { useLoading } from 'hooks/useLoading';

import User from 'components/access/User';
import OrderProgressStepper from 'components/ProgressStepper/OrderProgressStepper';
// import LawOrderProgressStepper from 'components/ProgressStepper/LawOrderProgressStepper';
import PenlyAccessUser from 'pages/PenlyAccessUser';
import AddProductsModalButton from 'components/access/AddProductsModalButton';
import { renderDate } from 'utils/helpers';
import { GridCard, GridContainer } from '../components/mui/Grid';

const PenlyAccessOrder = () => {
    const { organisationId, orderId } = useParams();
    const [order, setOrder] = useState();
    const [snack, setSnack] = useState();
    const [pdf, setPdf] = useState();
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [bookingId, setBookingId] = useState();
    const setLoading = useLoading();

    const organisation = useSelector(state => state.auth.user.organisations.find(org => org.organisationId === organisationId));

    const setOrderStatus = async (value) => {
        try {
            await teal.updateOrder(organisationId, orderId, { status: value });
            setStatus(value);
        } catch (error) {
            alert('kunne ikke opdatere ordre status');
        }
    };
    const getAffiliateIdFromOrder = (order) => {
        if(!order) return null;
        // Iterate through each item in the order
        // eslint-disable-next-line
        for (const item of order.items) {
            if (item.product && item.product.affiliateId) {
                // Return the first affiliateId found
                return item.product.affiliateId;
            }
        }

        // Return null if no affiliateId is found
        return null;
    };

    useEffect(() => {
        const fetch = async () => {
            setLoading('Indlæser ordre');
            const order = await teal.getOrder(organisationId, orderId);
            if (order.sharings && order.sharings.length> 0) {
                const userIds = [...new Set(order.sharings.reduce(
                    (accumulator, sharing) => sharing.exporter.userId ? [...accumulator, sharing.exporter.userId] : accumulator,
                    [],
                ))];
                const users = await Promise.all(userIds.map(async userId => {
                    const user = await penly.getUser(organisationId, userId);
                    const files = await penly.getFiles(organisationId, userId);
                    return { ...user, data: { ...user.data, files } };
                }));
                setUsers(users);
            }
            setOrder(order);
            setStatus(order.status);
            setLoading(false);
        };
        fetch();
    }, [orderId, setLoading]);

    const showCalendlyBookingEventLink =  config.env === 'test' || organisation.enabledFeatures?.includes('ShowCalendlyBookingEventLink');
    const calendlyBookingLink = generateCalendlyBookingId(
        config.nedsparingsCalendlyEventLink,
        order?.calendlyBookingIds?.[0],
        {
            email: order?.customer?.email,
            name: `${order?.customer.firstName} ${order?.customer.lastName}`,
        },
    );
    const handleLinkBookingId = async () => {
        try {
            await teal.updateOrder(organisationId, orderId, { bookingEventId: bookingId });
            window.location.reload();
        } catch (error) {
            alert('kunne ikke opdatere ordre status');
        }
    };
    const handleCancelProduct = async (index) => {
        try {
            const items = order.items.filter((_,i) => i !== index);
            const updatedOrder = await teal.updateOrder(organisationId, order.orderId, { items });
            setOrder(updatedOrder);
        } catch (error) {
            alert('kunne ikke opdatere ordre status');
        }
    };

    const handleRequestDataSharing = async () => {
        try {
            await penly.createInvitation(organisationId, order);
            setSnack({ message: 'Anmodning om datadeling sendt på mail', severity: 'success' });
        } catch (error) {
            setSnack({ message: 'Kunne ikke sende anmodning om datadeling', severity: 'error' });
        }
    };

    const isUserCreated = Boolean(order?.customer.userId);

    const showPenlyOrderPageLink = config.env === 'test' || organisation.enabledFeatures?.includes('ShowPenlyOrderPageLink');
    const penlyOrderPageLink = generatePenlyOrderPageLink(config.penlyBaseUrl, isUserCreated, order?.orderId, order?.customer?.email);

    const { customer, error, orderNumber, message, createdAt } = order || { };
    const isLaw = hasLawProduct(order);
    const affiliate = getAffiliateIdFromOrder(order);
    const calendlyQuestion = order?.bookings[0]?.calendlyEvent?.invitees?.questions_and_answers[0]?.question;
    const calendlyAnswer = order?.bookings[0]?.calendlyEvent?.invitees?.questions_and_answers[0]?.answer;
    return (
        <div className="App-content" style={{ marginTop: 20 }}>
            { error === 'NotFound' && (
                <Paper sx={{ p: 3, my: 3 }}>
                    <p>Ordren eksisterer ikke. Gå tilbage for at finde hvad du leder efter.</p>
                </Paper>
            ) }
            { orderNumber && (
                <>
                    <Paper sx={{ p: 3, my: 3 }}>
                        <Box display='flex' sx={{ my: 1 }}>
                            <Typography variant="h2">{ `Ordre #${order.orderNumber}` }</Typography>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, ml: 'auto' }}>
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select
                                    labelId="status-label"
                                    value={ status }
                                    onChange={ (e) => setOrderStatus(e.target.value) }
                                    label="Status"
                                >
                                    { statusOptions.map((status) => (
                                        <MenuItem key={ status.value } value={ status.value }>
                                            { status.name }
                                        </MenuItem>
                                    )) }
                                </Select>
                            </FormControl>
                            { isLaw &&
                            <Button sx={{ ml: 'auto', displayPrint: 'none' }} onClick={ window.print } variant="contained" endIcon={ <PrintOutlinedIcon /> }>Print</Button>

                            }
                        </Box>

                        <Divider sx={{ my: 3 }} />

                        <GridContainer>
                            <GridCard>
                                <CardContent>
                                    <Typography variant='h5'>Kunde</Typography>
                                    { createdAt && (<p><strong>Bestillingsdato: </strong>{ renderDate(createdAt, false) }</p>) }
                                    { customer.firstName && (<p><strong>Navn:</strong> { customer.firstName } { customer.lastName }</p>) }
                                    { customer.email && (<p><strong>Email:</strong> { customer.email }</p>) }
                                    { customer.phone && (<p><strong>Telefon:</strong> { customer.phone }</p>) }
                                    { affiliate && (<p><strong>Affiliate:</strong> { affiliate }</p>) }

                                </CardContent>
                            </GridCard>


                            <GridCard>
                                <CardContent>
                                    <Typography variant='h5'>Produkter</Typography>
                                    { order.items.map((item,i) => (
                                        <div key={ item.productId }>
                                            <p>{ item.quantity } x { item.product.name } til { item.product.type === 'subscription' ? `${renderKr(item.product.price.yearly?.now || item.product.price.yearly?.standard || 0)} / år`:renderKr(item.product.price.now || item.product.price.standard || item.product.price.standart || 0) }
                                                { item.product.addedByAdvisor &&
                                                <Button variant="text" color="error" onClick={ () => handleCancelProduct(i) }>Annuller</Button>

                                                }

                                            </p>

                                        </div>
                                    )) }
                                    { !isLaw &&
                                        <AddProductsModalButton order={ order } onClick={ (updatedOrder) =>  setOrder(updatedOrder) }/>
                                    }

                                </CardContent>
                            </GridCard>

                            <GridCard>
                                <CardContent>

                                    { order.bookings.length ? (
                                        <>
                                            <Typography variant='h5'>Fremtidigt møde</Typography>
                                            { order.bookings.map(booking => (
                                                <div key={ booking.bookingId }>
                                                    <p>
                                                        { capitalizeFirstLetter(renderDateLong(booking.startTime)) }, kl. { renderTime(booking.startTime) } - { renderTime(booking.endTime) } med { booking.vendorsAttendees.join(' og ') }
                                                    </p>
                                                </div>
                                            )) }
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant='h5'>Ingen fremtidige møder</Typography>

                                            { showCalendlyBookingEventLink && (
                                                <>
                                                    <Typography variant='h6'>Kundens link til booking af møde:</Typography>
                                                    <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ calendlyBookingLink }>
                                                        <TextField
                                                            variant="outlined"
                                                            value={ calendlyBookingLink }
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                },
                                                            }}
                                                            disabled
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            endIcon={ <ContentCopyIcon /> }
                                                            sx={{ ml: 1 }}
                                                        >
                                                            Copy
                                                        </Button>
                                                    </Clipboard>
                                                    <Clipboard style={{ marginTop:10, display: 'flex' }} component='div'>
                                                        <TextField
                                                            variant="outlined"
                                                            sx={{ backgroundColor:'white' }}
                                                            placeholder='Link BookingId'
                                                            onChange={ (e) => setBookingId(e.target.value) }
                                                        />
                                                        <Button
                                                            onClick={ handleLinkBookingId }
                                                            variant="contained"
                                                            endIcon={ <CableIcon /> }
                                                            sx={{ ml: 1 }}
                                                        >
                                                            Link
                                                        </Button>
                                                    </Clipboard>
                                                </>
                                            ) }

                                        </>
                                    ) }
                                </CardContent>
                            </GridCard>
                        </GridContainer>
                        <Divider sx={{ my: 3 }} />

                        {
                            order.dataCollectionProgress && order.dataCollectionProgress.currentStep &&

                            <div style={{ marginTop:30, marginBottom:30 }}>

                                { !isLaw &&

                                <>
                                    <h3>Dataindsamling status</h3>
                                    { !order.customer?.userId &&
                                    <p style={{ backgroundColor: '#f5b895', display: 'inline-block', padding:4 }}>
                                        Mangler profiloprettelse
                                    </p>
                                    }
                                    { order.customer?.userId && order?.sharings.length === 0 &&
                                    <p style={{ backgroundColor: '#f5b895', display: 'inline-block', padding:4 }}>
                                        Mangler samtykke til datadeling
                                    </p>
                                    }
                                    <OrderProgressStepper activeStep={ order.dataCollectionProgress.currentStep - (order.dataCollectionProgress.completed ? 0 : 1) }></OrderProgressStepper>
                                    {
                                        !order.dataCollectionProgress.completed && order?.sharings.length > 0 &&
                                        <Button
                                            variant="contained"
                                            onClick={ handleRequestDataSharing }
                                            sx={{ mt: 2 }}
                                        >
                                            Anmod kunde om at færdiggøre dataindsamling
                                        </Button>
                                    }

                                </>
                                // <LawOrderProgressStepper activeStep={order.dataCollectionProgress.currentStep - (order.dataCollectionProgress.completed ? 0 : 1)}></LawOrderProgressStepper>:

                                }

                            </div>

                        }
                        { showPenlyOrderPageLink && (
                            <>
                                <Typography variant='h6'>Kundens link til ordreside:</Typography>
                                <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ penlyOrderPageLink }>
                                    <TextField
                                        variant="outlined"
                                        value={ penlyOrderPageLink }
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },
                                            minWidth: 400,
                                        }}
                                        disabled
                                    />
                                    <Button
                                        variant="contained"
                                        endIcon={ <ContentCopyIcon /> }
                                        sx={{ ml: 1 }}
                                    >
                                        Kopier
                                    </Button>
                                </Clipboard>
                            </>
                        ) }
                        { calendlyQuestion && calendlyAnswer &&
                        <div style={{ marginTop:20, marginBottom:20 }}>
                            <Typography variant='h6'>Calendly spørgsmål: { calendlyQuestion }</Typography>
                            <GridCard style={{ maxWidth: 630, backgroundColor: 'rgb(238, 238, 238)' }}>

                                <CardContent>
                                    <p style={{ marginTop:0 }}> Svaret { renderDate(order.bookings[0].calendlyEvent.created_at, false) }</p>
                                    <p>{ calendlyAnswer }</p>
                                </CardContent>
                            </GridCard>

                        </div>
                        }
                        { message && (
                            <div style={{ marginTop:20, marginBottom:20 }}>
                                <Typography variant='h6'>Besked til rådgiver:</Typography>
                                <GridCard style={{ maxWidth: 630, backgroundColor: 'rgb(238, 238, 238)' }}>
                                    <CardContent>

                                        <p>{ message }</p>
                                    </CardContent>
                                </GridCard>

                            </div>
                        ) }
                        {  isLaw && users.map((user) => (
                            <div key={ user.userId }>
                                <Button style={{ marginTop: 16, marginBottom: 16 }} variant="contained" href={ `/access/users/${user.userId}` } target="_blank" rel="noopener noreferrer">Gå til profilside</Button>
                                <User user={ user } />

                                { ( false && (config.env === 'dev' || config.env === 'local')) && (
                                    <Section title="Raw debug">
                                        <textarea rows={ 8 } cols={ 80 } defaultValue={ JSON.stringify(user, null, 2) }/>
                                    </Section>
                                ) }
                            </div>
                        )) }

                    </Paper>
                    { !isLaw && order.customer?.userId && order.sharings?.length >0 &&
                    <div>
                        <Typography variant='h4' style={{ marginLeft:30 }}>Profil</Typography>
                        <PenlyAccessUser injectedUserId={ order.customer?.userId } isLaw={ isLaw }/>

                    </div>
                    }
                    { isLaw && order.productsSnapshot?.length !== 0 &&
                    <Paper sx={{ p: 3, my: 3, width:'100%', maxWidth:'857px', displayPrint: 'none' }}>
                        <TableContainer>
                            <Typography variant="h4">Produkttilbud</Typography>
                            <Table caria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Navn</TableCell>
                                        <TableCell align="left">Tilbudt Pris</TableCell>
                                        <TableCell align="left">Normal Pris</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { order.productsSnapshot.map((product) => (
                                        <TableRow key={ product.id }>
                                            <TableCell align="left">
                                                { product.name }
                                            </TableCell>
                                            <TableCell align="left">
                                                { renderKr(product.price.now || product.price.standard || product.price.standart) }
                                            </TableCell>
                                            <TableCell align="left">
                                                { renderKr(product.price.standard || product.price.standart) }
                                            </TableCell>
                                        </TableRow>
                                    )) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    }

                </>
            ) }

            { (false && (config.env === 'dev' || config.env === 'local')) && (
                <Section title="Raw debug">
                    <textarea rows={ 8 } cols={ 80 } defaultValue={ JSON.stringify(order, null, 2) }/>
                </Section>
            ) }

            <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'success' }>{ snack?.message }</MuiAlert>
            </Snackbar>

            <PdfDialog
                pdf={ pdf }
                onClose={ setPdf }
            />
        </div>
    );
};

export default PenlyAccessOrder;

const generateCalendlyBookingId = (calendlyEventLink, penlyBookingId, { email, name }) => {
    let link = `${config.nedsparingsCalendlyLink}?utm_term=penly-booking-id-${penlyBookingId}`;
    if (email) link = `${link}&email=${email}`;
    if (name) link = `${link}&name=${name}`;
    return link;
};

const generatePenlyOrderPageLink = (penlyLink, isUserCreated, orderId, email) => {
    let link = `${penlyLink}/mit-penly/ordrer/${orderId}`;
    if (isUserCreated) return link;

    link = `${penlyLink}/opret?email=${email}&disableEmailField=true&destination=${link}`;

    return link;
};
function hasLawProduct(order) {
    // Check if the order object exists and has valid 'items' array
    if (order && order.items && Array.isArray(order.items)) {
        // Iterate over each item in the 'items' array
        // eslint-disable-next-line
        for (const item of order.items) {
            // Check if the item has a 'product' property and if it's a law-related product
            if (item.product && isLawProduct(item.product)) {
                return true; // Return true if a law-related product is found
            }
        }
    }
    return false; // Default to false if no law-related product is found or order data is invalid
}
function isLawProduct(product) {
    if (product && product.categories && Array.isArray(product.categories)) {
        return product.categories.includes('law');
    }
    return false; // Default to false if product data is invalid or category is missing
}
