import axios from 'axios';
import { config } from 'utils/config';
import persistor from 'utils/persistor';

const request = axios.create({ baseURL: config.apiUrl });

request.interceptors.request.use(requestConfig => {
    const { token } = persistor.getTokens();
    requestConfig.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    return requestConfig;
});

async function getAllScrapes() {
    const response = await request('/pensions-info-scrapes');
    return response.data.scrapes;
}
async function getAllScrapesByPenlyUserId(organisationId, penlyUserId) {
    const response = await request(`/${organisationId}/pensions-info-scrapes/by-penly-userid/${penlyUserId}`);
    return response.data.scrapes;
}
async function getScrape({ scrapeId }) {
    const response = await request(`/pensions-info-scrapes/${scrapeId}`);
    return response.data.scrape;
}

async function getScrapeFile({ scrapeId }) {
    const response = await request(`/pensions-info-scrapes/${scrapeId}/file`, { responseType: 'blob' });
    return response.data;
}

async function createScrape({ pdf }) {
    const formData = new FormData();
    formData.set('pensionsInfoPdf', pdf);

    const response = await request({
        method: 'post',
        url: '/pensions-info-scrapes',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data.scrape;
}

async function convertContentToScrape({ content, fileId, penlyUserId, organisationId }) {
    const response = await request({
        method: 'post',
        url: '/pensions-info-scrapes/with-content',
        data: { content, fileId, penlyUserId, organisationId },
    });
    return response.data.scrape;
}

async function deleteScrape({ scrapeId }) {
    const response = await request({
        method: 'delete',
        url: `/pensions-info-scrapes/${scrapeId}`,
    });
    return response.data.scrape;
}

// Get the logged in Teal Business users data
async function getUser() {
    const { data }  = await request.get('/user');
    return data.user;
}

async function createOrganisation(organisation) {
    const { data } = await request({
        method: 'post',
        url: '/organisations',
        data: organisation,
    });

    return data.organisation;
}

async function createSharing(organisationId, sharing) {
    const { data } = await request({
        method: 'post',
        url: `/${organisationId}/access/sharings`,
        data: sharing,
    });

    return data.sharing;
}

async function updateIntegration(organisationId, type, config) {
    const { data } = await request({
        method: 'put',
        url: `/${organisationId}/integrations/${type}`,
        data: config,
    });

    return data;
}

async function getIntegrations(organisationId) {
    const { data }  = await request.get(`/${organisationId}/integrations`);
    return data.integrations;
}

async function getAllSharings(organisationId) {
    const { data } = await request(`/${organisationId}/access/sharings`);

    return data.sharings;
}

async function getAllOrders(organisationId, params) {
    const { data : { orders, count } } = await request(`/${organisationId}/access/orders`, { params } );

    return { orders, count };
}

async function getAccessUsers(organisationId) {
    const result = await request(`/${organisationId}/access/users`);
    return result.data.users;
}

async function getSharing(organisationId, sharingId) {
    let sharing;
    try {
        const result = await request(`/${organisationId}/access/sharings/${sharingId}`);
        sharing = result.data.sharing;
    } catch (error) {
        if (error.response.status === 404) return { sharingId, error: 'NotFound' };
        throw error;
    }

    return sharing;
}

async function getOrder(organisationId, orderId) {
    let order;
    try {
        const result = await request(`/${organisationId}/access/orders/${orderId}`);
        order = result.data.order;
    } catch (error) {
        if (error.response.status === 404) return { order, error: 'NotFound' };
        throw error;
    }

    return order;
}

export async function updateOrder(organisationId, orderId, partialOrderData) {
    const { data } = await request({
        url: `/${organisationId}/access/orders/${orderId}`,
        method: 'patch',
        data: partialOrderData,
    });

    return data.order;
}

async function getSharings(organisationId, userId) {
    let result;
    try {
        result = await request.get(`/${organisationId}/access/sharings`, { params: { userId } });
    } catch (error) {
        if (error.response.status === 404) return { userId, error: 'NotFound' };
        throw error;
    }

    return result.data.sharings;
}

async function getPenlyUser(organisationId, userId) {
    const response = await request({
        url: `/${organisationId}/access/penly/api-v1/user`,
        headers: { 'x-user-id': userId },
    });

    return response.data.user;
}

async function getFiles(organisationId, userId) {
    const response = await request({
        url: `/${organisationId}/access/penly/api-v1/user/files`,
        headers: { 'x-user-id': userId },
    });
    return response.data.files;
}

async function uploadFile(organisationId, userId, fileInfo) {
    const formData = new FormData();
    formData.append('json', JSON.stringify({ fileType: fileInfo.type, shareWithUser: fileInfo.shareWithUser }));
    formData.append('file', fileInfo.file);
    const { data } = await request({ method: 'POST', url: `/${organisationId}/access/penly/api-v1/user/files`, data: formData, headers: { 'x-user-id': userId } });
    return data.file;
}

async function deleteFile(organisationId, userId, fileId) {
    await request({ method: 'DELETE', url: `/${organisationId}/access/penly/api-v1/user/files/${fileId}`, headers: { 'x-user-id': userId } });
    return fileId;
}

async function getConversations(organisationId, userId) {
    const response = await request({
        url: `/${organisationId}/access/penly/api-v1/user/conversations`,
        headers: { 'x-user-id': userId },
    });
    return response.data.conversations;
}
async function createMessage(organisationId, userId, messageData) {
    const { data } = await request({
        method: 'post',
        headers: { 'x-user-id': userId },
        url: `/${organisationId}/access/penly/api-v1/user/conversations/messages`,
        data: messageData,
    });
    return data.conversation;
}

async function getFileBinary(organisationId, userId, fileId) {
    const response = await request({
        url: `/${organisationId}/access/penly/api-v1/user/files/${fileId}/binary`,
        headers: { 'x-user-id': userId },
        responseType: 'blob',
    });
    return response.data;
}
async function invitePartnerToKreditdata(organisationId, userId, data) {
    const response = await request({
        method: 'post',
        url: `/${organisationId}/access/penly/api-v1/user/integrations/kreditdata/partner/pensionsInfo`,
        headers: { 'x-user-id': userId },
        data,
    });

    return response.data.link;
}
async function createInvitation(organisationId, order) {
    const response = await request({
        method: 'post',
        url: `/${organisationId}/access/penly/api-v1/user/invitations`,
        headers: { 'x-user-id': order.customer.userId },
        data: { orderId: order.orderId },
    });

    return response.data.link;
}

async function getAffiliate(organisationId, affiliateId, { details } = { }) {
    const { data: { affiliate } } = await request(`/${organisationId}/access/penly/api-v1/affiliates/${affiliateId}`, { params: { details } });
    return affiliate;
}

async function createOrder(organisationId, orderData) {
    const { data } = await request({
        method: 'post',
        url: `/${organisationId}/access/penly/api-v1/user/orders`,
        data: orderData,
    });
    return data.order;
}

async function getUsersInOrganisation(orgId) {
    const { data } = await request.get(`/${orgId}/users`);
    return data.users;
}

export const teal = {
    getUser,
    createOrganisation,
    getAllScrapes,
    getAllScrapesByPenlyUserId,
    getScrape,
    getScrapeFile,
    createScrape,
    convertContentToScrape,
    deleteScrape,
    createSharing,
    getSharing,
    getSharings,
    getAllSharings,
    getAccessUsers,
    getAllOrders,
    getOrder,
    updateOrder,
    updateIntegration,
    getIntegrations,
    getUsersInOrganisation,
};

export const penly = {
    getUser: getPenlyUser,
    getFiles,
    getFileBinary,
    uploadFile,
    deleteFile,
    getConversations,
    createMessage,
    invitePartnerToKreditdata,
    createInvitation,
    getAffiliate,
    createOrder,
};

export default { teal, penly };
