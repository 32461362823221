
/* eslint-disable max-len */
import {
    GenderEnum,
    MaritalStatusEnum,
    ChildOfEnum,
    ChildResidenceEnum,
    SavingsTypeEnum,
    DebtTypeEnum,
    OccupationEnum,
    PartnerRelationEnum,
    InvestmentRiskLevelEnum,
    YesNoDontKnowEnum,
    HousingTypeEnum,
    FileTypes,
    CreditUnionEnum,
    LoanTypeEnum,
    ExtraPensionsTypeEnum,
} from 'utils/enums';

const textCollections = [
    {
        type: GenderEnum,
        texts: [
            { id: GenderEnum.Male, text: 'Mand' },
            { id: GenderEnum.Female, text: 'Kvinde' },
        ],
    },
    {
        type: MaritalStatusEnum,
        texts: [
            { id: MaritalStatusEnum.Unknown, text: 'Ukendt' },
            { id: MaritalStatusEnum.Married, text: 'Gift' },
            { id: MaritalStatusEnum.Single, text: 'Single' },
            { id: MaritalStatusEnum.Divorced, text: 'Skilt' },
        ],
    },
    {
        type: ChildOfEnum,
        texts: [
            { id: ChildOfEnum.Unknown, text: 'Ukendt' },
            { id: ChildOfEnum.Both, text: 'Fællesmyndighed' },
            { id: ChildOfEnum.You, text: 'Dig' },
            { id: ChildOfEnum.Partner, text: 'Partner' },
        ],
    },
    {
        type: ChildResidenceEnum,
        texts: [
            { id: ChildResidenceEnum.Unknown, text: 'Ukendt' },
            { id: ChildResidenceEnum.LivingAtHome, text: 'Hjemmeboende' },
            {
                id: ChildResidenceEnum.LivingPartlyAtHome,
                text: 'Delvis hjemmeboende',
            },
            { id: ChildResidenceEnum.NotLivingAtHome, text: 'Udeboende' },
        ],
    },
    {
        type: SavingsTypeEnum,
        texts: [
            { id: SavingsTypeEnum.BankAccount, text: 'Alm. bankkonto' },
            { id: SavingsTypeEnum.Investment, text: 'Alm. investeringsdepot' },
            { id: SavingsTypeEnum.ChildSavings, text: 'Børneopsparing' },
            { id: SavingsTypeEnum.ForeignSavings, text: 'Formue i udlandet' },
            { id: SavingsTypeEnum.Other, text: 'Anden opsparing' },
            { id: SavingsTypeEnum.StockSavingsAccount, text: 'Aktiesparekonto (ASK)' },

        ],
    },
    {
        type: ExtraPensionsTypeEnum,
        texts: [
            { id: ExtraPensionsTypeEnum.Ratepension, text: 'Ratepension' },
            { id: ExtraPensionsTypeEnum.LivsvarigLivrente, text: 'Livsvarig livrente' },
            { id: ExtraPensionsTypeEnum.OphørendeLivrente, text: 'Ophørende livrente' },
            { id: ExtraPensionsTypeEnum.Aldersopsparing, text: 'Aldersopsparing' },
            { id: ExtraPensionsTypeEnum.Ophørspension, text: 'Ophørspension' },
            { id: ExtraPensionsTypeEnum.Kapitalpension, text: 'Kapitalpension' },
            { id: ExtraPensionsTypeEnum.Tjestemandspension, text: 'Tjenestemandspension' },
        ],
    },
    {
        type: DebtTypeEnum,
        texts: [
            { id: DebtTypeEnum.HouseLoan, text: 'Boliggæld' },
            { id: DebtTypeEnum.ConsumerLoan, text: 'Forbrugslån' },
            { id: DebtTypeEnum.StudentLoan, text: 'Studielån' },
            { id: DebtTypeEnum.CarLoan, text: 'Billån' },
            { id: DebtTypeEnum.PersonalLoan, text: 'Personligt lån' },
            { id: DebtTypeEnum.Overdraft, text: 'Kassekredit' },
            { id: DebtTypeEnum.HouseLoanBank, text: 'Boliglån i bank' },
            { id: DebtTypeEnum.MortgageLoan, text: 'Realkreditlån' },
            { id: DebtTypeEnum.OtherDebt, text: 'Anden gæld' },
        ],
    },
    {
        type: OccupationEnum,
        texts: [
            { id: OccupationEnum.Unknown, text: 'Ukendt' },
            { id: OccupationEnum.PermanentJobFullTime, text: 'Lønmodtager' },
            { id: OccupationEnum.SelfEmployed, text: 'Selvstændig' },
            { id: OccupationEnum.Student, text: 'Studerende' },
            { id: OccupationEnum.Unemployed, text: 'Ledig' },
            { id: OccupationEnum.Pensioner, text: 'Pensionist u/arbejde' },
            { id: OccupationEnum.PensionerWithJob, text: 'Pensionist m/arbejde' },
            { id: OccupationEnum.EarlyRetirementPay, text: 'Efterløn' },
        ],
    },
    {
        type: PartnerRelationEnum,
        texts: [
            { id: PartnerRelationEnum.Unknown, text: 'Ukendt' },
            { id: PartnerRelationEnum.Spouse, text: 'Ægtefælle' },
            { id: PartnerRelationEnum.Cohabitant, text: 'Samlever' },
        ],
    },
    {
        type: InvestmentRiskLevelEnum,
        texts: [
            { id: InvestmentRiskLevelEnum.Low, text: 'Lav' },
            { id: InvestmentRiskLevelEnum.Medium, text: 'Mellem' },
            { id: InvestmentRiskLevelEnum.High, text: 'Høj' },
        ],
    },
    {
        type: YesNoDontKnowEnum,
        texts: [
            { id: YesNoDontKnowEnum.Yes, text: 'Ja' },
            { id: YesNoDontKnowEnum.No, text: 'Nej' },
            { id: YesNoDontKnowEnum.DontKnow, text: 'Ved ikke' },
        ],
    },
    {
        type: HousingTypeEnum,
        texts: [
            { id: HousingTypeEnum.Owner, text: 'Ejerbolig' },
            { id: HousingTypeEnum.HousingCooprative, text: 'Andelsbolig' },
            { id: HousingTypeEnum.Renting, text: 'Lejebolig' },
            { id: HousingTypeEnum.ParentPurchase, text: 'Forældrekøbsbolig' },
            { id: HousingTypeEnum.VacationHouse, text: 'Sommerhus' },
            { id: HousingTypeEnum.CommercialProperty, text: 'Erhvervsejendom' },
            { id: HousingTypeEnum.VacantLand, text: 'Ubebygget grund' },
            { id: HousingTypeEnum.ForeignProperty, text: 'Bolig i udlandet' },
            { id: HousingTypeEnum.Other, text: 'Andet' },
        ],
    },
    {
        type: FileTypes,
        texts: [
            { id: FileTypes.BetalingsServiceFile, text: 'Betalingservice' },
            { id: FileTypes.BudgetFile, text: 'Budget' },
            { id: FileTypes.DepositOverviewFile, text: 'Depotoversigt fra bank' },
            { id: FileTypes.PaycheckFile, text: 'Lønseddel' },
            { id: FileTypes.PensionsInfoFile, text: 'PensionsInfo' },
            { id: FileTypes.PartnerPensionsInfoFile, text: 'PensionsInfo fra partner' },
            { id: FileTypes.ExpensesOverviewFile, text: 'Posteringsoversigt fra bank' },
            { id: FileTypes.BankYearlyOverviewFile, text: 'Årsoversigt fra bank' },
            { id: FileTypes.MortgageYearlyOverviewFile, text: 'Årsoversigt fra realkreditinstitut' },
            { id: FileTypes.TaxYearlyOverviewFile, text: 'Årsopgørelse fra SKAT' },
            { id: FileTypes.TestamentFile, text: 'Testamente' },
            { id: FileTypes.PrenuptialAgreementFile, text: 'Ægtepagt' },
            { id: FileTypes.FuturePowerOfAttorneyFile, text: 'Fremtidsfuldmagt' },
            { id: FileTypes.RaadTilPengeBudgetFile, text: 'Budget fra RådTilPenge.dk' },
            { id: FileTypes.MortgageReceipt, text: 'Terminskvittering' },
            { id: FileTypes.Advice, text: 'Dokument fra rådgiver' },
            { id: FileTypes.OtherFile, text: 'Andet' },

        ],
    },
    /* Mortgage check */
    {
        type: CreditUnionEnum,
        texts: [
            { id: CreditUnionEnum.RealkreditDanmark, text: 'Realkredit Danmark' },
            { id: CreditUnionEnum.Nordea, text: 'Nordea' },
            { id: CreditUnionEnum.Nykredit, text: 'Nykredit' },
            { id: CreditUnionEnum.JyskeRealkreditBRFKredit, text: 'Jyske Bank' },
            { id: CreditUnionEnum.Totalkredit, text: 'Totalkredit' },
        ],
    },
    {
        type: LoanTypeEnum,
        texts: [
            { id: LoanTypeEnum.FixedRate, text: 'Fastforrentet' },
            { id: LoanTypeEnum.FixedRateInstallmentFree10, text: 'Fast Afdragsfri 10' },
            { id: LoanTypeEnum.FixedRateInstallmentFree30, text: 'Fast Afdragsfri 30' },
            { id: LoanTypeEnum.Bankloan, text: 'Banklån' },
            { id: LoanTypeEnum.FKort, text: 'F-kort' },
            { id: LoanTypeEnum.F1, text: 'F1' },
            { id: LoanTypeEnum.F2, text: 'F2' },
            { id: LoanTypeEnum.F3, text: 'F3' },
            { id: LoanTypeEnum.F4, text: 'F4' },
            { id: LoanTypeEnum.F5, text: 'F5' },
            { id: LoanTypeEnum.FlexLifeF1, text: 'FlexLife F1' },
            { id: LoanTypeEnum.FlexLifeF3, text: 'FlexLife F3' },
            { id: LoanTypeEnum.FlexLifeF5, text: 'FlexLife F4' },
            { id: LoanTypeEnum.FlexKort, text: 'FlexKort' },
            { id: LoanTypeEnum.FKortPlus, text: 'F Kort +' },
        ],
    },
];

export const getTextCollection = (collectionType, skipDefaultText = true, filterIds = null) => {
    let collection = textCollections.find(c => c.type === collectionType)?.texts;

    if (filterIds) {
        collection = collection.filter(t => !filterIds.includes(t.id));
    }

    return !skipDefaultText ? collection : collection?.filter(t => t.id !== 0);
};

export const getTextCollectionText = (collectionType, textId) => textCollections.find(c => c.type === collectionType)?.texts.find(t => t.id.toString() === textId.toString())?.text;

export const getTextCollectionTextValue = (collectionType, textId, valueName) => textCollections.find(c => c.type === collectionType)?.texts.find(t => t.id.toString() === textId.toString())[valueName];

export const klatpensionLandingPageFAQEntries = [
    {
        questionName: 'Hvad skal jeg gøre, hvis jeg har en klatpension?',
        acceptedAnswerText: `En klatpension er kendetegnet ved, at den over en årrække forsvinder helt af sig selv, hvis ikke du gør noget aktivt.
        Standardanbefalingen fra pensionsselskaberne er at samle dine klatpensioner med din aktive pension og i nogle
        tilfælde er det, den eneste mulighed du har pga. bindinger. Det vil en rådgiver kunne hjælpe dig med at få overblik
        over.<br />
        Vi anbefaler, at du tager en uforpligtende snak med med os, og får gennemgået din pensionsanalyse inden du evt.
        går videre med betalt rådgivning.<br />
        Ved at tage Penlys PensionsTjek får du vished om, hvorvidt du har en klatpension og hjælp til at finde ud af, hvad
        du skal stille op med den.`,
    },
    {
        questionName: 'Hvordan kan Penly hjælpe mig, hvis jeg har en klatpension?',
        acceptedAnswerText:
            `
            Når du har taget et Penly PensionsTjek, og det viser sig, at du har klatpensioner, guider Penly dig
videre i processen. Vi viser dig, hvornår du kan forvente din klatpension går op i røg og hvor meget du
kan forvente at pengene yngler, hvis du investerer dem.<br />
Vi har gjort det nemt for dig at booke en uforpligtende samtale med Penly til en gennemgang af din
pensionsanalyse.
            `,
    },
    {
        questionName: 'Hvad er en klatpension?',
        acceptedAnswerText:
            'En klatpension er det samme som en hvilende pension, dog hvor beløbet er under 50.000 kr. En pension bliver hvilende eller passiv i det øjeblik, der ikke indbetales til den. I Danmark har vi tilsammen 7 mia. kr. stående på klatpensioner og 500 mia. kr. stående i alt på hvilende ordninger. En klatpension risikerer nemt at blive spist af gebyrer, mens en hvilende ordning nøjes med at vokse meget langsomt.',
    },
    {
        questionName: 'Hvad er en hvilende ordning?',
        acceptedAnswerText:
            'En hvilende ordning er en pensionsordning, du ikke længere indbetaler aktivt til. Når en pensionsordning bliver passiv, forhøjes administrationsomkostningerne og din pensionsordning vil derfor vokse langsommere, end da den var aktiv. Vi danskere har 2,3 mio. hvilende ordninger og tilsammen har vi 500 mia. kr. stående på passive ordninger.',
    },
    {
        questionName: 'Hvad er forskellen på en klatpension og en hvilende ordning?',
        acceptedAnswerText:
            'Forskellen på en hvilende ordning og en klatpension afgøres af størrelsen af det beløb du har på ordningen. Hvis beløbet er under 50.000 kr. og du ikke aktivt indbetaler på ordningen er det en klatpension. En klatpension risikerer nemt at blive spist af gebyrer, mens en hvilende ordning nøjes med at vokse meget langsomt.',
    },
    {
        questionName: 'Hvem har klatpensioner og hvilende ordninger?',
        acceptedAnswerText:
            'Alle kan have klatpensioner eller hvilende ordninger. Det handler typisk om, hvor mange jobs du har haft og af hvor lang tids varighed de har været. Mange klatpensioner opstår ofte i de tidlige år på jobmarkedet, hvor et fritidsjob kan have resulteret i en klatpension. Mens hvilende ordninger kan opstå gennem hele dit arbejdsliv, fordi du skifter job og ikke slår den nye og gamle pensionsordning sammen.',
    },
    {
        questionName: 'Hvorfor opstår klatpensioner og hvilende ordninger?',
        acceptedAnswerText:
            'Klatpensioner opstår typisk, hvis du skifter arbejde relativt hurtigt. Hvis du har været kort tid i et job, er din pension tilsvarende lille. Mange tænker ikke over, om de har klatpensioner og dit nye pensionsselskab spørger ikke nødvendigvis ind til det. Det er derfor vigtigt, at du selv tager hånd om dem. Hvilende ordninger opstår på samme måde, dog behøver det ikke at være en kort ansættelse.',
    },
    {
        questionName: 'Hvordan ved jeg om der er særlige garantier, rettigheder eller andet der knytter sig til min klatpension?',
        acceptedAnswerText:
            'Ved at tage en snak med en uvildig rådgiver, får du svar på, om du kan flytte din klatpension eller om der er særlige garantier og rettigheder, der knytter sig til din klatpension, som gør det mindre attraktivt eller umuligt at flytte den. Penly anbefaler til hver en tid, at du taler med en rådgiver inden du gør noget.',
    },
    {
        questionName: 'Hvorfor forsvinder en klatpension?',
        acceptedAnswerText: `Klatpensioner er kendetegnet ved, at de er så små, at de taber værdi på grund af de faste omkostninger, der er knyttet en pensionsordning. Pensionsselskaber og -kasser opkræver et fast årligt gebyr på cirka 800-1000 kr., uanset størrelsen på din pensionsopsparing. Har du derfor en klatpension på 25.000 kr., svarer et årligt gebyr på 1.000 kr. til en negativ årlig rente på 4% af din opsparing.
                Herudover betaler du ofte mindst 1% i indirekte omkostninger, dvs. omkostninger til dem der styrer fondene bag din pension. Samlet set er du oppe på en omkostning på hele 5%. Så selv om din klatpension burde vokse som følge af, at den er investeret, kan den ikke følge med udgifterne til pensionsselskabet. I løbet af få år kan klatpensionen derfor være helt væk pga. gebyrer.`,
    },
    {
        questionName: 'Hvad koster det at flytte en klatpension?',
        acceptedAnswerText:
            'Hvis din klatpension er på 20.000 kr. eller derunder koster det dig ikke noget at flytte den. Er din klatpension eller hvilende ordning større koster det typisk i omegnen af 1.800 kr. som automatisk trækkes fra, når dine penge overføres. Har du skiftet job inden for de sidste tre år, vil dit nye pensionsselskab eller pensionskasse ofte betale dette gebyr, hvis du samler ordningen med din nye ordning.',
    },
    {
        questionName: 'Hvad sker der med min hvilende ordning, hvis ikke jeg gør noget?',
        acceptedAnswerText:
            'Hvilende ordninger er ofte dyrere i administrationsgebyrer end aktive pensionsordninger. Det betyder, at din hvilende pension vokser langsommere end din aktive pension, da du ikke får lige så stor udbytte af renters rente effekten. Vi anbefaler, at du i videst muligt omfang begrænser omfanget af hvilende ordninger. Dette kan gøres ved, at du flytter ordningen til et sted med lavere omkostninger, eller samler dine pensioner sammen hos din aktive pensionsudbyder.',
    },
];

export const klatpensionFAQEntries = [
    {
        questionName: 'Hvad er en klatpension?',
        acceptedAnswerText:
            'En klatpension er det samme som en hvilende pension, dog hvor beløbet er under 50.000 kr. En pension bliver hvilende eller passiv i det øjeblik, der ikke indbetales til den. I Danmark har vi tilsammen 7 mia. kr. stående på klatpensioner og 500 mia. kr. stående i alt på hvilende ordninger. En klatpension risikerer nemt at blive spist af gebyrer, mens en hvilende ordning nøjes med at vokse meget langsomt.',
    },
    {
        questionName: 'Hvad er en hvilende ordning?',
        acceptedAnswerText:
            'En hvilende ordning er en pensionsordning, du ikke længere indbetaler aktivt til. Når en pensionsordning bliver passiv, forhøjes administrationsomkostningerne og din pensionsordning vil derfor vokse langsommere, end da den var aktiv. Vi danskere har 2,3 mio. hvilende ordninger og tilsammen har vi 500 mia. kr. stående på passive ordninger.',
    },
    {
        questionName: 'Hvad er forskellen på en klatpension og en hvilende ordning?',
        acceptedAnswerText:
            'Forskellen på en hvilende ordning og en klatpension afgøres af størrelsen af det beløb du har på ordningen. Hvis beløbet er under 50.000 kr. og du ikke aktivt indbetaler på ordningen er det en klatpension. En klatpension risikerer nemt at blive spist af gebyrer, mens en hvilende ordning nøjes med at vokse meget langsomt.',
    },
    {
        questionName: 'Hvem har klatpensioner og hvilende ordninger?',
        acceptedAnswerText:
            'Alle kan have klatpensioner eller hvilende ordninger. Det handler typisk om, hvor mange jobs du har haft og af hvor lang tids varighed de har været. Mange klatpensioner opstår ofte i de tidlige år på jobmarkedet, hvor et fritidsjob kan have resulteret i en klatpension. Mens hvilende ordninger kan opstå gennem hele dit arbejdsliv, fordi du skifter job og ikke slår den nye og gamle pensionsordning sammen.',
    },
    {
        questionName: 'Hvorfor opstår klatpensioner og hvilende ordninger?',
        acceptedAnswerText:
            'Klatpensioner opstår typisk, hvis du skifter arbejde relativt hurtigt. Hvis du har været kort tid i et job, er din pension tilsvarende lille. Mange tænker ikke over, om de har klatpensioner og dit nye pensionsselskab spørger ikke nødvendigvis ind til det. Det er derfor vigtigt, at du selv tager hånd om dem. Hvilende ordninger opstår på samme måde, dog behøver det ikke at være en kort ansættelse.',
    },
    {
        questionName: 'Hvordan ved jeg om der er særlige garantier, rettigheder eller andet der knytter sig til min klatpension?',
        acceptedAnswerText:
            'Ved at tage en snak med en uvildig rådgiver, får du svar på, om du kan flytte din klatpension eller om der er særlige garantier og rettigheder, der knytter sig til din klatpension, som gør det mindre attraktivt eller umuligt at flytte den. Penly anbefaler til hver en tid, at du taler med en rådgiver inden du gør noget.',
    },
    {
        questionName: 'Hvorfor forsvinder en klatpension?',
        acceptedAnswerText: `Klatpensioner er kendetegnet ved, at de er så små, at de taber værdi på grund af de faste omkostninger, der er knyttet en pensionsordning. Pensionsselskaber og -kasser opkræver et fast årligt gebyr på cirka 800-1000 kr., uanset størrelsen på din pensionsopsparing. Har du derfor en klatpension på 25.000 kr., svarer et årligt gebyr på 1.000 kr. til en negativ årlig rente på 4% af din opsparing.
                Herudover betaler du ofte mindst 1% i indirekte omkostninger, dvs. omkostninger til dem der styrer fondene bag din pension. Samlet set er du oppe på en omkostning på hele 5%. Så selv om din klatpension burde vokse som følge af, at den er investeret, kan den ikke følge med udgifterne til pensionsselskabet. I løbet af få år kan klatpensionen derfor være helt væk pga. gebyrer.`,
    },
    {
        questionName: 'Hvad koster det at flytte en klatpension?',
        acceptedAnswerText:
            'Hvis din klatpension er på 20.000 kr. eller derunder koster det dig ikke noget at flytte den. Er din klatpension eller hvilende ordning større koster det typisk i omegnen af 1.800 kr. som automatisk trækkes fra, når dine penge overføres. Har du skiftet job inden for de sidste tre år, vil dit nye pensionsselskab eller pensionskasse ofte betale dette gebyr, hvis du samler ordningen med din nye ordning.',
    },
    {
        questionName: 'Hvad sker der med min hvilende ordning, hvis ikke jeg gør noget?',
        acceptedAnswerText:
            'Hvilende ordninger er ofte dyrere i administrationsgebyrer end aktive pensionsordninger. Det betyder, at din hvilende pension vokser langsommere end din aktive pension, da du ikke får lige så stor udbytte af renters rente effekten. Vi anbefaler, at du i videst muligt omfang begrænser omfanget af hvilende ordninger. Dette kan gøres ved, at du flytter ordningen til et sted med lavere omkostninger, eller samler dine pensioner sammen hos din aktive pensionsudbyder.',
    },
];

export const pricesPageFAQEntries = [
/*
    {
        questionName: 'Er de 15 minutters gratismøde blot et salgsmøde?',
        acceptedAnswerText:
            'Nej, på de 15 minutter gennemgår den uvildige rådgiver de svar, du har fået i Penlys PensionsTjek. Du har også mulighed for at spørge ind til ting, du ikke forstår. Hvis rådgiveren kan se, at du vil kunne få en økonomisk gevinst af at få yderligere rådgivning, bliver du selvfølgelig gjort opmærksom på det.',
    },
*/
    {
        questionName: 'Hvorfor er det nødvendigt at oprette en profil, før jeg skal tale med rådgiveren?',
        acceptedAnswerText:
            'Når du opretter en profil og sender data igennem til rådgiveren, har rådgiveren mulighed for at forberede sig inden samtalen. Samtidig vil det digitale pensionstjek også vise dig, hvad du bør spørge ind til, når du taler med den uvildige rådgiver.',
    },
    /*
    {
        questionName: 'Hvad hvis der er andet end pension, jeg vil tale med den uvildige rådgiver om?',
        acceptedAnswerText:
            'Når du først er igennem til den uvildige rådgiver, kan du tale med denne om alle de områder han eller hun kan rådgive dig indenfor. Mange rådgivere har flere specialer inden for pension, investering, lån osv.',
    },
*/
    {
        questionName: 'Er rådgiverne ansat ved Penly?',
        acceptedAnswerText:
            'Nej, Penly har ikke rådgivere ansat. Vi samarbejder med markedets dygtigste rådgivere og vi stiller krav til deres uvildighed, deres licens og deres etik. Så du kan få den bedste rådgivning og en god oplevelse.',
    },
    {
        questionName: 'Hvorfor skal jeg lave et Penly PensionsTjek for at bestille et ØkonomiTjek?',
        acceptedAnswerText:
            'Selv om du måske ikke tænker over det til daglig, er pension en af de største aktiver i din økonomi. Når du tager et ØkonomiTjek er det derfor afgørende, at rådgiveren også har indsigt i dine pensioner, da der skal tages højde for, hvad du har af ønsker og drømme for fremtiden. En del af ØkonomiTjekket er ligeledes at få kigget dine pensioner, pensionsforsikringer mv. igennem.',
    },
];

export const lawPageFAQEntries = [
    {
        questionName: 'Vi er gift og ejer et fælles hus. Den ene har børn fra tidligere. Den anden har ingen børn. Hvordan er vi stillet, hvis den med børn dør først?',
        acceptedAnswerText: '<p>Uden testamente:</p><p>Afdødes barn arver 25 % af ægteparrets samlede nettoformue, mens 75 % af den fælles samlede nettoformue går til den længstlevende ægtefælle. Hvis afdødes barn giver samtykke til at længstlevende ægtefælle kan sidde i uskiftet bo, kan barnet vente med at modtage sin arv efter sin forælder til den længstlevende ægtefælle dør eller skifter boet (dette anbefales som udgangspunkt ikke). Når den længstlevende ægtefælle dør, følger arven arvelovens almindelige regler. Det vil aldrig automatisk være førstafdødes barn fra tidligere, som arver længstlevende ægtefælle.</p><p>Med testamente:</p><p>Ægtefællerne kan med testamente give hinanden mest muligt i arv og begrænse børnearven. Hvis dette ønskes, arver afdødes barn nu 1/16 af ægteparrets samlede nettoformue, mens efterlevende ægtefælle nu modtager 15/16 af ægteparrets samlede nettoformue. Ægteparret kan i deres testamente vælge at lade førstafdødes barn arve, når den længstlevende ægtefælle dør.</p>',
    },
    {
        questionName: 'Vi er gift og ejer et fælles hus og har begge børn fra tidligere ægteskaber. Hvordan er vi stillet, hvis den ene dør først?',
        acceptedAnswerText: '<p>Uden testamente:</p><p>Afdødes to biologiske børn arver 25 % (til deling) af ægteparrets samlede nettoformue, mens 75 % af den fælles samlede nettoformue går til den længstlevende ægtefælle. Når den længstlevende ægtefælle dør, arver dennes to biologiske børn alene. Den førstafdødes to børn arver således ikke noget.</p><p>Med testamente:</p><p>Ægtefællerne kan med testamente give hinanden mest muligt i arv og begrænse børnearven til at starte med. Hvis dette ønskes, arver afdødes biologiske børn nu 1/16 (til deling) af ægteparrets samlede nettoformue, mens efterlevende ægtefælle nu modtager 15/16 af ægteparrets samlede nettoformue. Ægteparret kan i deres testamente vælge at lade førstafdødes børn arve på lige fod med længstlevendes børn, hvis det ønskes, så de fire børn hver arver ¼, når den længstlevende ægtefælle dør.</p>',
    },
    {
        questionName: 'Vi er gift og har fælles børn, hvorfor skal vi overhovedet have et testamente?',
        acceptedAnswerText: 'Der kan nogle gange være en skattemæssig besparelse i at skifte boet frem for at sidde i uskiftet bo. I dette tilfælde, kan det give mening at oprette et testamente, hvor ægtefællerne tilgodeser hinanden mest muligt, så det ikke er så dyrt at skifte med børnene. Skilsmisseprocenten i Danmark er fortsat ret høj, og derfor kan det være værd at overveje, om børnene skal arve som særeje via testamente. \n' +
            '\n' +
            'OBS! I nogle tilfælde kan arven til børnene begrænses yderligere ved også at oprette en ægtepagt. Det anbefales under alle omstændigheder at rådføre sig med en jurist inden for området, inden oprettelse af dokumenterne.',
    },
    {
        questionName: 'Vi er ugifte, men har boet sammen i 2 år. Er vi ikke lige så godt stillet som gifte?',
        acceptedAnswerText: 'Nej, I har ingen automatisk arveret efter hinanden. Hvis I ønsker at sikre hinanden arvemæssigt, skal I oprette et testamente. \n' +
            'Det er en myte, der florerer, at man automatisk arver hinanden, hvis man har boet sammen mere end to år. Arven vil i så fald fordeles efter arvelovens almindelige regler.',
    },
    {
        questionName: 'Vi er ugifte og har fælles hus og den ene part har barn. Hvordan er vi stillet med og uden et testamente?',
        acceptedAnswerText: '<p>Med testamente:</p>' +
            '<p>Med et gensidigt testamente hvor I begge tilgodeser hinanden med mest muligt, kan den, der har børn, som udgangspunkt give den efterlevende samlever 7/8. ' +
            'Den samlever, der ikke har børn, kan med testamente give den anden samlever hele sin formue.\n' +
            '</p><p>Uden testamente:</p><p>' +
            'De samlevende vil aldrig automatisk arve hinanden uden testamente, uanset hvor mange år de har boet sammen. Arven efter samleveren, der har børn, vil gå til samleverens egne børn. Arven efter samleveren, som ikke har børn, vil fordeles efter arvelovens almindelige regler. Her vil udgangspunktet være dennes forældre som arver, altså aldrig automatisk samleveren.</p>',
    },
    {
        questionName: 'Vi er ugifte og har testamente - hvad er arveafgiften?',
        acceptedAnswerText: 'Når I har gensidigt testamente og har boet sammen på samme adresse i mere end to år, eller har børn sammen, eller venter barn sammen, er arveafgiften/boafgiften på 15 %. Vær opmærksom på, at der er et bundfradrag som kan udnyttes.\n' +
            'Havde I ikke havde haft testamente, og havde I ikke opfyldt ovenstående krav, havde I slet ikke arvet hinanden.\n' +
            'Det kan være en god idé at få set sit testamente igennem for at være sikker på, at det er godt nok til at opnå 15 % i arveafgift/boafgift. Alliance Advokater Hørsholm går gratis og uforpligtende jeres testamente igennem, hvis I ønsker det.',
    },
    {
        questionName: 'Vi er ugifte og bor sammen i mit hus. Vi har ingen børn og intet testamente. Hvad skal vi være opmærksomme på?',
        acceptedAnswerText: 'I arver ikke automatisk hinanden.\n' +
            'Den, der ikke ejer huset, vil stå i store problemer, hvis den anden samlever, som ejer huset dør først. Hvis I ønsker at sikre hinanden, og sikre en mulighed for at begge kan blive boende i huset ved dødsfald, skal der oprettes et testamente.',
    },
    {
        questionName: 'Jeg lever alene og har kun biologiske børn. Behøver jeg et testamente?',
        acceptedAnswerText: 'Hvis du ønsker dine børn ikke skal dele arven efter dig med deres ægtefælle, i tilfælde af skilsmisse, kan det give rigtig god mening at oprette testamente, da du i testamentet kan bestemme, at arven tilfalder dine børn som særeje.\n' +
            'Det kunne også være, at du ville give dine børnebørn en del af arven, f.eks. til uddannelse, indskud til lejebolig, køb af ejendom eller andet. Du kan i et testamente bestemme, at en andel af arven tilfalder børnebørnene, mens resten tilfalder dine børn.',
    },
];
