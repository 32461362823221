import Section from 'components/Section';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConversations } from 'actions/access';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress component
import PenlyAccessUserMessages from './PenlyAccessUserMessages';
import PenlyAccessUserNotes from './PenlyAccessUserNotes';
import PenlyAIAssistent from './PenlyAccessAIAssistent';

const PenlyAccessMessages = ({ penlyUser }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const { organisationId } = useParams();;
    const [messages, setUserMessages] = useState([]);
    const [notes, setUserNotes] = useState([]);
    const [aiConversations, setAiConversations] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    useEffect(() => {
        const fetch = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const conversations = (await dispatch(getConversations(organisationId, penlyUser.userId)));
                const m = conversations?.filter(x => x.audience ==='all')[0]?.messages ?? [];
                const n = conversations?.filter(x => x.audience ==='importer' && x.type !== 'ai')[0]?.messages ?? [];
                const ai = conversations?.filter(x => x.type ==='ai');
                setUserMessages(m);
                setUserNotes(n);
                setAiConversations(ai);

            } catch (error) {
                alert('Der skete en fejl');
            }
            setLoading(false); // Set loading to false after fetching
        };
        if(penlyUser.userId){
            fetch();
        }

    }, [dispatch, organisationId, penlyUser.userId]);
    return (
        <>
            <Section title="Rådgiver AI">
                { loading ? (
                    <CircularProgress />
                ) : (
                    <PenlyAIAssistent conversations={ aiConversations } user={ user } penlyUser={ penlyUser }></PenlyAIAssistent>
                ) }
            </Section>
            <Section title="Egne noter">
                { loading ? (
                    <CircularProgress />
                ) : (
                    <PenlyAccessUserNotes init={ notes } user={ user } penlyUser={ penlyUser }></PenlyAccessUserNotes>
                ) }
            </Section>
            <Section title="Brugerbeskeder">
                { loading ? (
                    <CircularProgress />
                ) : (
                    <PenlyAccessUserMessages init={ messages } user={ user } penlyUser={ penlyUser }></PenlyAccessUserMessages>
                ) }
            </Section>
        </>
    );
};


export default PenlyAccessMessages;
