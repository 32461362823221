import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Content from 'components/Content';
import Section from 'components/Section';
import { Link, useParams } from 'react-router-dom';
import paths from 'paths';
import { fetchUsersInOrganisation } from 'store/slices/users';

function Page() {
    const dispatch = useDispatch();
    const { organisationId } = useParams();
    const organisation = useSelector(state => state.auth.user.organisations.find(org => org.organisationId === organisationId));

    useEffect(() => {
        if (organisationId) {
            dispatch(fetchUsersInOrganisation(organisationId));
        }
    }, [dispatch, organisationId]);

    return (
        <Content>
            { organisation && !organisation.verified && (
                <Section title='Du kan snart bruge Teal Business'>
                    <p>
                        Din virksomhed er ved at blive godkendt. Vi kontakter dig når godkendelsen er gennemført.<br/>
                        Har du spørgsmål eller kommentarer, så kontakt os på <a href="mailto:support@tealco.dk">support@tealco.dk</a>.
                    </p>
                </Section>
            ) }

            { organisation && organisation.verified && !organisation.active && (
                <Section title='Du kan ikke bruge Teal Business'>
                    <p>
                        Din virksomheds adgang er blevet suspenderet.
                        Kontakt os på <a href="mailto:support@tealco.dk">support@tealco.dk</a>.
                    </p>
                </Section>
            ) }

            { organisation && organisation.verified && organisation.active && (
                <>
                    <Section title={ `Dashboard for ${organisation.name}` } >
                        <p>Lad os høre hvad I gerne vil have på jeres dashboard.</p>
                    </Section>
                    <Section>
                        <Link to={ paths.penlyAccess.getPath(organisationId) }>
                            Penly Access
                        </Link>
                    </Section>
                </>
            ) }


        </Content>
    );
}

export default Page;
