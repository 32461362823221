import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';
import { getAffiliateProduct } from '../../store/slices/products';
import { createOrder } from '../../store/slices/orders';
import { getPriceFrequency, renderPriceFrequency, constructPrice } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: 20,
        minWidth: 120,
    },
}));

const CreateOrderDialog = ({ open, onClose, onAccept, isLaw }) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const { organisationId } = useParams();
    const navigate = useNavigate();
    const products = useSelector((state) => state.products.products);
    const user = useSelector(state => state.auth.user);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [customer, setCustomer] = useState({ firstName: '', lastName: '', email: '' });
    const [error, setError] = useState({ email: '', products: '' });
    const affiliateId = isLaw ? 'alliance-advokater' : 'penly';
    useEffect(() => {

        dispatch(getAffiliateProduct(organisationId, affiliateId));
    }, [dispatch, isLaw]);

    const resetState = () => {
        setSelectedProducts([]);
        setCustomer({ firstName: '', lastName: '', email: '' });
        setError({ email: '', products: '' });
    };

    const handleProductChange = (event) => {
        const selectedIds = event.target.value;
        const updatedProducts = selectedIds.map(id => {
            const existingProduct = selectedProducts.find(product => product.product.id === id);
            if (existingProduct) {
                return existingProduct;
            }
            const product = products.find(product => product.id === id);
            const defaultPrice = product.price.hourly
                ? product.price.standard
                : product.price.now || product.price.standard || product.price.yearly?.now || product.price.yearly?.standard || product.price.monthly?.standard;
            const priceFrequency = getPriceFrequency(product.price);
            return { product, price: defaultPrice || 0, priceFrequency };
        });
        setSelectedProducts(updatedProducts);
    };

    const handlePriceChange = (index) => (event) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts[index].price = parseFloat(event.target.value) || 0;
        setSelectedProducts(updatedProducts);
    };

    const handleCustomerChange = (field) => (event) => {
        setCustomer({ ...customer, [field]: event.target.value });
    };

    const handleAccept = async () => {
        let hasError = false;
        const newError = { email: '', products: '' };

        if (!customer.email) {
            newError.email = 'Email skal udfyldes.';
            hasError = true;
        }

        if (selectedProducts.length === 0) {
            newError.products = 'Du skal vælge mindst ét produkt.';
            hasError = true;
        }

        setError(newError);

        if (!hasError) {
            const orderToCreate = {
                items: selectedProducts.map(({ product, price, priceFrequency }) => ({
                    product: {
                        ...product,
                        price: constructPrice(price, priceFrequency),
                    },
                })),
                customer,
                createdBy: { userId: user.userId, name: user.fullName },
                vendor: organisationId,
                calendlyBookingId: uuidv4(),
                disableCalendlyLookup: true,
            };
            const res = await dispatch(createOrder(organisationId, orderToCreate));
            if (res.isError) {
                alert('Kunne ikke oprette ordre');
            } else {
                if (onAccept) {
                    onAccept();
                }
                navigate(`${window.location.pathname}/orders/${res.orderId}`);
                resetState(); // Reset state after navigation
            }
        }
    };

    return (
        <Dialog open={ open } onClose={ onClose } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Opret ny ordre</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Vælg produkter, rediger priser og indtast kundeoplysninger for den nye ordre</DialogContentText>
                <Card variant="outlined" sx={{ marginBottom: 2, marginTop: 2, borderColor: '#009094' }}>
                    <CardContent sx={{ paddingBottom: '12px !important' }}>
                        <Typography gutterBottom sx={{ color: '#009094', fontSize: 12, margin:0 }}>
                            Kunde
                        </Typography>
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 6 }>
                                <TextField
                                    label="Fornavn"
                                    value={ customer.firstName }
                                    onChange={ handleCustomerChange('firstName') }
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={ 6 }>
                                <TextField
                                    label="Efternavn"
                                    value={ customer.lastName }
                                    onChange={ handleCustomerChange('lastName') }
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            label="Email"
                            value={ customer.email }
                            onChange={ handleCustomerChange('email') }
                            fullWidth
                            margin="normal"
                            error={ !!error.email }
                            helperText={ error.email }
                        />
                    </CardContent>
                </Card>
                <FormControl className={ styles.formControl } fullWidth error={ !!error.products }>
                    <InputLabel id="multi-select-label">Vælg produkter</InputLabel>
                    <Select
                        labelId="multi-select-label"
                        multiple
                        value={ selectedProducts.map(({ product }) => product.id) }
                        onChange={ handleProductChange }
                        input={ <OutlinedInput label="Vælg produkter" /> }
                        renderValue={ (selected) => selected.map(id => products.find(product => product.id === id)?.name).join(', ') }
                        MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'bottom', horizontal: 'left' } }}
                    >
                        { products.map(({ id, name }) => (
                            <MenuItem key={ id } value={ id }>
                                <Checkbox checked={ selectedProducts.some(({ product }) => product.id === id) } />
                                <ListItemText primary={ name } />
                            </MenuItem>
                        )) }
                    </Select>
                    { error.products && <Typography color="error" variant="caption">{ error.products }</Typography> }
                </FormControl>
                { selectedProducts.map((item, index) => (
                    <Grid container spacing={ 2 } key={ index } alignItems="center">
                        <Grid item xs={ 4 }>
                            <TextField
                                label="Produkt"
                                value={ item.product.name }
                                disabled
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={ 4 }>
                            <TextField
                                label="Pris"
                                type="number"
                                value={ item.price }
                                onChange={ handlePriceChange(index) }
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={ 4 }>
                            <TextField
                                label="Pris frekvens"
                                value={ renderPriceFrequency(item.priceFrequency) }
                                disabled
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                )) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose } color="warning">
                    Fortryd
                </Button>
                <Button
                    onClick={ handleAccept }
                    autoFocus
                    color="primary"
                    variant="contained"
                >
                    Opret
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrderDialog;
