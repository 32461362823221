/* eslint-disable */ 
import React, { useState, useMemo, useRef, useEffect, useCallback  } from "react"
import MUIRichTextEditor from 'mui-rte'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromHTML, ContentState, convertToRaw, EditorState } from 'draft-js'
import EmailIcon from '@mui/icons-material/Email'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button, CircularProgress } from "@mui/material"

export default function TextEditor( {onSubmit, onEmail, contentKey, saveButtonText, emailButtonText, defaultMessage, id, loading}) {
    const [snack, setSnack] = useState();
    const editorRef = useRef()
    const [editorKey, setEditorKey] = useState(0);
    const handleReset = () => setEditorKey(prevKey => prevKey + 1);
    useEffect(() => {   
        console.log("focus")
        editorRef.current?.focus()
    }, [editorKey,id,defaultMessage, loading])

    const handleSave = useCallback(() => {
        onSubmit(getCurrentContent(), contentKey)
        localStorage.removeItem(contentKey)
        handleReset()
    }, [onSubmit, contentKey]);
    
    const handleSaveCallback = useCallback(() => {
        handleSave();
    }, [handleSave]);
    // Add event listener for keydown event
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                handleSaveCallback();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSaveCallback]);

 



  // Holds the value of the editor
    const defaultButtons = ["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "code", "clear", "my-callback"]
    // 1. Convert the HTML
    const contentHTML = useMemo(
        () =>
        convertFromHTML( defaultMessage || getCurrentContent() || '') ,
        [defaultMessage]
      )
    // 2. Create the ContentState object
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    
    // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
    const content = JSON.stringify(convertToRaw(state))
    const customControls = [ ]

    const handleEmail = async () => {
        const content = getCurrentContent()
        const blob = new Blob([content], { type: "text/html" });
        const richTextInput = new ClipboardItem({ "text/html": blob });
        await navigator.clipboard.write([richTextInput]);
        setSnack({message: 'Besked gemt i udklipsholder'})
        onEmail(content)
    }
    function getCurrentContent(){
        return JSON.parse(localStorage.getItem(contentKey))
    }

    return (
        <>
              <MUIRichTextEditor 
              key={editorKey} 
      label="Skriv her..."  
      defaultValue={content}
      ref={editorRef}
      controls={[...defaultButtons, "email"]}    
      customControls={customControls}
    onChange={(editorState) => {
        const content = stateToHTML(editorState.getCurrentContent());
        localStorage.setItem(contentKey, JSON.stringify(content))
    }}
    readOnly={loading}
    
    />
        <Button sx={{ margin:2, marginLeft:0 }} variant='outlined' onClick={ handleSave } color="primary" disabled={loading}> 
            {loading ? <CircularProgress size={24} /> : (saveButtonText || 'Gem')}
        </Button>
        {onEmail && 
            <Button  sx={{ margin:0 }}  variant='outlined' onClick={ handleEmail } color="primary" disabled={loading}> 
                {emailButtonText || 'Kopiér til email'}
            </Button>
        }
        <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack() }
            >
                <MuiAlert severity={ snack?.severity || 'success' }>{ snack?.message }</MuiAlert>
            </Snackbar>
        </>

  )
}


