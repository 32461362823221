export function renderDate(date, showWeekday = true) {
    let d = date;
    if(!d) return '';
    if (typeof d === 'number') d = new Date(date);
    if (typeof d === 'string') d = new Date(date);
    if(typeof date === 'string' && isNaN(d) && date.includes('-')){
        const dateParts = date.split('-');
        const reverse = `${dateParts[2]  }-${  dateParts[1]  }-${  dateParts[0]}`;
        d = new Date(reverse);
    }
    if (typeof d.toDate !== 'undefined') d = date.toDate();
    return d.toLocaleString('da-DK', { weekday: showWeekday? 'long': undefined, year: 'numeric', month: 'long', day: 'numeric' });
}

export function renderDateTime(datetime) {
    let d = datetime;
    if(!d) return '';
    if (typeof d === 'number') d = new Date(datetime);
    if (typeof d === 'string') d = new Date(datetime);
    if(typeof datetime === 'string' && isNaN(d) && datetime.includes('-')){
        const dateParts = datetime.split('-');
        const reverse = `${dateParts[2]  }-${  dateParts[1]  }-${  dateParts[0]}`;
        d = new Date(reverse);
    }
    if (typeof d.toDate !== 'undefined') d = datetime.toDate();
    return d.toLocaleTimeString('da-DK', { year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit', minute:'2-digit' });
}

export function mapStatus(key){
    let value = key;
    switch (key) {
        case 'new':
            value = 'Ny';
            break;
        case 'finished':
            value = 'Færdig';
            break;
        case 'invalid':
            value = 'Inkonsistente værdier';
            break;
        case 'running':
            value = 'Indlæser';
            break;
        case 'failed':
            value = 'Fejl';
            break;
        case 'deleted':
            value = 'Slettet';
            break;
        default:
            break;
    }
    return value;
}
export function mapScrapeErrorMessage (message) {
    if (message === 'Not Valid PensionsInfo File') {
        return 'Vi kan ikke genkende denne fil som en valid PensionsInfo rapport. Sørg for at rapporten kommer direkte fra pensionsInfo.dk og ikke er scannet eller har være brugt af andre systemer.';
    }
    if (message === 'Not Danish') {
        return 'Vi kan ikke genkende denne fil som værende på dansk. Vores service understøtter ikke andre sprog end dansk, så hent venligst den danske version af rapporten';
    }
    return 'Der skete en fejl i indlæsning af din rapport.';
}

export const renderKr = (str, label = 'kr.', maximumDigits = 0, minimumDigits = 0, showSmallMillion = false) => {
    let x = str;
    if (x === undefined) {
        return 0;
    }
    if (showSmallMillion) {
        return `${Math.round(x / 1000000)} m.`;
    }
    if (maximumDigits === 0) {
        x = Math.round(x / 100) * 100;
    }
    return `${x.toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })}${label && ` ${label}`}`;
};

export function showFile(blob, filename) {
    if (blob.size === 0) {
        alert('Kunne ikke hente fil'); // eslint-disable-line no-alert
        return;
    }
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}

export function calculateAge (birthday) {
    let d = birthday;
    if(!d) return '';
    if (typeof d === 'number') d = new Date(birthday);
    if (typeof d === 'string') d = new Date(birthday);
    if(typeof birthday === 'string' && isNaN(d) && birthday.includes('-')){
        const dateParts = birthday.split('-');
        const reverse = `${dateParts[2]  }-${  dateParts[1]  }-${  dateParts[0]}`;
        d = new Date(reverse);
    }
    const now = new Date();

    let years = (now.getFullYear() - d.getFullYear());
    // eslint-disable-next-line no-mixed-operators
    if (now.getMonth() < d.getMonth() || (now.getMonth() === d.getMonth()) && now.getDate() < d.getDate()) {
        years -= 1;
    }

    return years;
}

export function getScrapeStatusOrValidity(scrape) {
    if (scrape.content?.validationErrors?.length > 0) {
        return 'invalid';
    }
    return scrape.status;
}

export function convertDateToDanishCPRFirst6Digits(dateString) {
    if(!dateString ) return '';
    const cprPattern = /^\d{6}-\d{4}$/;
    if (!cprPattern.test(dateString)) {
        return '';
    }
    // Parse the input date string
    const date = parseCPR(dateString);
    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear().toString().substr(-2);
    // console.log(date.getDate().toString())
    // Concatenate and return the first 6 digits of the Danish CPR number
    const danishCPR = day + month + year;
    return danishCPR;
}
function parseCPR(cpr) {
    // Extract the date part and the century indicator
    const datePart = cpr.slice(0, 6);
    const centuryIndicator = parseInt(cpr.slice(7, 8), 10);

    // Extract day, month, and year
    const day = parseInt(datePart.slice(0, 2), 10);
    const month = parseInt(datePart.slice(2, 4), 10) - 1; // JS months are 0-based
    let year = parseInt(datePart.slice(4, 6), 10);

    // Determine the full year based on the century indicator
    if (centuryIndicator < 4) {
        year += 1900;
    } else if (centuryIndicator < 9) {
        year += 2000;
    } else {
        year += 1900; // fallback case for invalid century indicators
    }

    // Create and return the date object
    return new Date(year, month, day);
}

export const getPriceFrequency = (price) => {
    if (price.hourly) return 'hourly';
    if (price.yearly) return 'yearly';
    if (price.monthly) return 'monthly';
    return 'one-time';
};

export const renderPriceFrequency = (priceFrequency) => {
    switch (priceFrequency) {
        case 'hourly':
            return 'Timebaseret';
        case 'yearly':
            return 'Årlig';
        case 'monthly':
            return 'Månedlig';
        default:
            return 'Engangs';
    }
};

export const renderPriceCurrency = (priceFrequency) => {
    switch (priceFrequency) {
        case 'hourly':
            return 'kr. / time';
        case 'yearly':
            return 'kr. / år';
        case 'monthly':
            return 'kr. / måned';
        default:
            return 'kr.';
    }
};

export const constructPrice = (price, priceFrequency) => {
    if (priceFrequency === 'hourly') {
        return { now: price, hourly: true };
    } if (priceFrequency === 'yearly') {
        return { yearly: { now: price } };
    } if (priceFrequency === 'monthly') {
        return { monthly: { now: price } };
    }
    return { now: price };
};
