import { createSlice } from '@reduxjs/toolkit';
import api from 'api';

const initialState = {
    products: [],
    loading: false,
    error: null,
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        /* eslint-disable no-param-reassign */
        requested(state) {
            state.loading = true;
        },
        fetched(state, action) {
            state.loading = false;
            state.products = action.payload.products;
        },
        failed(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
    },
});

const { actions } = productsSlice;

export default productsSlice;

export function getAffiliateProduct(organisationId, affiliateId) {
    return async (dispatch) => {
        dispatch({ type: actions.requested.type });
        try {
            const affiliate = await api.penly.getAffiliate(organisationId, affiliateId, { details: true });
            const filteredProducts = Object.values(affiliate.products).filter(
                (product) => product.receipt?.cta?.url && product.id !== 'pensionanalysis-review-chatbot',
            );
            dispatch({ type: actions.fetched.type, payload: { products: filteredProducts } });
            return affiliate;
        } catch (error) {
            dispatch({ type: actions.failed.type, payload: { error } });
            return error;
        }
    };
}
