/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemText } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import DOMPurify from 'dompurify';
import TextEditor from "./TextEditor"
import { createMessage } from 'actions/access';
import { renderDateTime } from 'utils/helpers';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { marked } from 'marked';

export default function PenlyAIAssistent({ user, penlyUser, conversations }) {
  const [showEditor, setShowEditor] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [history, setHistory] = useState([]);
  const dispatch = useDispatch();
  const { organisationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [snack, setSnack] = useState();
  const [defaultMessage, setDefaultMessage] = useState();
  const contentKey = 'PenlyAIAssistent'
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // @ts-ignore
    setHistory(conversations.sort((a, b) => new Date(b.latestMessage.date) - new Date(a.latestMessage.date)));
  }, [conversations]);
  console.log("conversations",conversations)
  console.log("messages",messages)
  console.log("currentConversation", currentConversation)
  console.log("defaultMessage", defaultMessage)
  console.log("history", history)
  const handleSubmit = async (content) => {
    setLoading(true);
    const messageData = {
      audience: 'importer',
      type: 'ai',
      name: user.fullName,
      message: { content },
      existingConversationId: currentConversation?._id,
    };
    const message = {
      _id: messages.length + 1,
      content, // Convert Markdown to HTML
      sender: { userId: user.userId, name: user.fullName },
    };
    setMessages([...messages, message]);
    setCurrentContent('<p></p>');
    const conversationOrError = await dispatch(createMessage(organisationId, penlyUser.userId, messageData));
    setLoading(false);
    console.log("conversationOrError", conversationOrError)
    if (conversationOrError.name === 'AxiosError') {
      setSnack({message: 'Besked kunne ikke sendes'})
      localStorage.setItem('PenlyAIAssistent', JSON.stringify(content));
      setCurrentContent(content);
    } else {
      
      setCurrentConversation(conversationOrError);
      setMessages([...messages, message, conversationOrError.latestMessage]);
      
      if(!currentConversation?._id){
        setHistory([conversationOrError, ...history]);
      }
    }
  }
  function setCurrentContent(content){
    console.log("content1", content)  
    setDefaultMessage(content)
    localStorage.setItem(contentKey, JSON.stringify(content))
}

  const handleNewConversation = () => {
    setCurrentConversation(null);
    setCurrentContent('<p></p>');
    setMessages([]);
    setShowEditor(true);
  };

  const handleConversationClick = (conversation) => {
    setCurrentConversation(conversation);
    setCurrentContent('<p></p>');
    setMessages(conversation.messages);
    setShowEditor(true);
  };
  const date = new Date().toLocaleString('da-DK', { year: 'numeric', month: 'numeric', day: 'numeric' });
  const templates = [
    {
      name: 'Nulstil',
      content: '<p></p>'
    },
    {
      name: 'Opsummering',
      content: `<p><strong>Generer en opsummering af kundens økonomi og anbefalinger baseret kundens data. Angiv alt i punktform og i markdown format. Angiv alle pensioner, men kun med navn(leverandør), pensionstype (fx ratepension og livrente - som underpunkter for hver type under navn) og enten nuværende værdi eller udbetaling ved pension (tag udgangspunkt i pensionsalder). Frie midler er formue, som ikke er placeret i pension eller bolig. Er intent angivet undlad at skrive noget. Afslut med følgende: 'Du kan med fordel købe en times rådgivning til 1.499 kr. for hjælp med flytning til Nordnet, og sparring om dine spørgsmål. Du kan booke en time i min kalender her: https://calendly.com/penly/pensionsradgivning-1-time'. Brug følgende format:</strong></p> <h2>[Navn] ([Alder])</h2> <p><b>Løn:</b> [Løn] kr./md.</p> <p><b>Pensionsalder:</b> [Pensionsalder]</p> <b>Folkepensionsalder:</b> [Folkepensionsalder]</p> <h2>Forbrug</h2> <p><b>Månedligt forbrug:</b> [Forbrug] kr./md.</p> <h2>Frie midler</h2> <p><b>Beløb:</b> [Frie midler]</p> <h2>Pensioner</h2> <p>[Pensioner]</p> <h2>Noter [${date}]</h2> <p>[Noter og anbefalinger]</p>`
     },
    {
    name: 'Pensionsanalyse',
    content: `<p><strong>Generér en professionel og kundevenlig pensionsanalyse baseret på følgende oplysninger:</strong></p> <ul> <li><strong>Kundens alder:</strong> [indsæt alder]</li> <li><strong>Ønsket pensionsalder:</strong> [indsæt alder]</li> <li><strong>Nuværende pensionsopsparing:</strong> [indsæt beløb]</li> <li><strong>Månedlig indbetaling:</strong> [indsæt beløb]</li> <li><strong>Forventet årligt afkast (%):</strong> [indsæt procent]</li> <li><strong>Forventet levealder:</strong> [indsæt alder]</li> <li><strong>Ønsket månedlig udbetaling i pensionstilværelsen:</strong> [indsæt beløb]</li> <li><strong>Andre pensionsordninger eller aktiver:</strong> [beskriv kort]</li> </ul> <p><strong>Analysen skal indeholde:</strong></p> <ol> <li>En kort og letforståelig indledning, der forklarer kundens nuværende situation.</li> <li>En prognose for, hvor meget kunden kan forvente at have i pension ved pensionsalderen baseret på de angivne faktorer.</li> <li>En vurdering af, om kundens nuværende opsparing og indbetalinger er tilstrækkelige for at opnå den ønskede pension.</li> <li>Eventuelle anbefalinger til justeringer i opsparing, investering eller pensionsalder.</li> <li>En konklusion med en opsummering af analysen og næste skridt for kunden.</li> </ol>`
   },
   {
    name: 'Nedsparingsanalyse',
    content: `<p><strong>Generér en professionel og kundevenlig nedsparingsanalyse baseret på følgende oplysninger:</strong></p> <ul> <li><strong>Kundens alder:</strong> [indsæt alder]</li> <li><strong>Forventet pensionsalder:</strong> [indsæt alder]</li> <li><strong>Nuværende pensionsopsparing:</strong> [indsæt beløb]</li> <li><strong>Øvrige opsparinger/investeringer:</strong> [beskriv kort]</li> <li><strong>Ønsket månedlig udbetaling i pensionstilværelsen:</strong> [indsæt beløb]</li> <li><strong>Forventet årligt afkast (%):</strong> [indsæt procent]</li> <li><strong>Forventet levealder:</strong> [indsæt alder]</li> <li><strong>Eventuelle øvrige indtægtskilder (f.eks. folkepension, ejendomsindtægter):</strong> [beskriv kort]</li> <li><strong>Arv eller større udgifter i sigte:</strong> [beskriv kort]</li> </ul> <p><strong>Analysen skal indeholde:</strong></p> <ol> <li>En kort status på kundens nuværende økonomiske situation forud for pensionering.</li> <li>En beregning af, hvor længe kundens formue kan holde med den ønskede udbetalingsprofil.</li> <li>Vurdering af om udbetalingen er bæredygtig, samt eventuelle risici (markedsudsving, levetidsrisiko).</li> <li>Anbefalinger til optimering af nedsparingen (f.eks. justering af udbetaling, investeringsstrategi, skatteoptimering).</li> <li>Konklusion og handlingsplan for kunden med næste skridt.</li> </ol>`

   }
];

  const formatDate = (date) => {
    const parsedDate = new Date(date); // Use native JavaScript Date function
    const now = new Date();
    const differenceInDays = Math.floor((now - parsedDate) / (1000 * 60 * 60 * 24));
    if(!date) return 'Ny';
    if (differenceInDays === 0) {
      return 'I dag';
    } else if (differenceInDays === 1) {
      return 'I går';
    } else if (differenceInDays < 7) {
      return `${differenceInDays} dage siden`;
    } else {
      return renderDateTime(date);
    }
  };

  const handleCopyToClipboard = async (content) => {
    navigator.clipboard.write([
      new ClipboardItem({
          "text/html": new Blob([marked(content)], { type: "text/html" }),
          "text/plain": new Blob([marked(content)], { type: "text/plain" })
      })
  ]).then(() => {
      setSnack({ message: 'Copied to clipboard', severity: 'success' });
    }).catch(() => {
      setSnack({ message: 'Failed to copy', severity: 'error' });
    });
  };

  return (
    <div style={{ display: 'flex', width: '100%', maxHeight: '1000px', overflow: 'hidden' }}>
      <div style={{ flex: 7, paddingRight: '1%', maxWidth:740, overflowY: 'auto' }}>
        <p>Lav analyser og generér tekst ved brug af AI. AI assistenten har adgang til brugerdata, Penlys produktinformationer samt rådgivningsinstruktioner.</p>
        {showEditor &&
                <>
                        <p><strong>Skabeloner:</strong></p>
        {templates.map((template) => <Button key={template.name} style={{marginRight:16, marginBottom:16}} variant='outlined' onClick={() => setCurrentContent(template.content)}>{template.name}</Button>)}

        </>
        }

                <Snackbar
                  open={ !!snack }
                  autoHideDuration={ 6000 }
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={ () => setSnack(undefined) }
              >
                  <MuiAlert severity={ snack?.severity || 'error' }>{ snack?.message }</MuiAlert>
              </Snackbar>
        <List>
          {messages.map((message) => (
            <ListItem
              key={message._id}
              sx={{
                backgroundColor: 'grey.200',
                padding: 2,
                marginBottom: 2,
                borderRadius: 1,
                wordBreak: 'break-word',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflowX: 'auto', // Add horizontal scrolling
                '&.selectedConversation': {
                  // styles for the selected conversation
                },
                '&:hover': {
                  // styles for when the item is hovered
                },
              }}
            >


              <ListItemText
                primary={message.sender.name}
                secondary={renderDateTime(message.date)}
                primaryTypographyProps={{ variant: 'h6' }}
                secondaryTypographyProps={{
                  variant: 'body2',
                }}
              />
               <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(message.content ?? '')) }}
                />
              {message.sender.userId === "AI" && (
                <Button
                  startIcon={<ContentCopyIcon />}
                  onClick={() => handleCopyToClipboard(message.content)}
                  size="small"
                  sx={{ marginTop: 1 }}
                >
                  Kopiér
                </Button>
              )}
            </ListItem>
          ))}
        </List>
        <div >
          {showEditor &&
                  <TextEditor 
                  defaultMessage={defaultMessage} 
                  saveButtonText={'Send'} 
                  contentKey={contentKey} 
                  onSubmit={handleSubmit} 
                  loading={loading}
                  id={currentConversation?._id ?? '' + currentConversation?.messages.length ?? 0 }
                  
                   /> 
          }

        </div>
      
      </div>
      <div style={{ flex: 3, marginLeft: '1%', maxWidth: '300px' }}>
        
        <Button 
          variant='contained' 
          color='primary' 
          onClick={handleNewConversation} 
          style={{ marginBottom: 16, marginTop: 16 }}
          startIcon={<AddIcon />}
        >
          Ny samtale
        </Button>
        {history.length > 0 && <h2>Historik</h2>}
        <List
          style={{
            maxHeight: '100%',
            overflowY: 'auto',
          }}
        >
          {history
            .map((conversation) => (
              <ListItem 
                key={conversation._id} 
                button 
                onClick={() => handleConversationClick(conversation)}
                sx={{
                  backgroundColor: currentConversation?._id === conversation._id ? 'grey.300' : 'grey.200',
                  padding: 1,
                  marginBottom: 1,
                  borderRadius: 1,
                  height: 60,
                  '&:hover': {
                    backgroundColor: 'grey.300',
                  },
                }}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    
                  }}
                >
                  <div style={{height:25}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(conversation.messages[0].content.substring(0, 50)) }} />
                  <small style={{height:10}}>{formatDate(conversation.latestMessage.date)}</small>
                </div>
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
}
