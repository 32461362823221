/* eslint-disable react/prop-types, no-nested-ternary */

import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import { uploadFile } from 'actions/access';
import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles(theme => ({
    spacing: { marginTop: theme.spacing(2) },
    dropzone: {
        height: '100px',
        cursor: 'pointer',
        borderStyle: 'solid',
        borderColor: '#AAA',
        border: 1,
        background: 'var(--color-containersecondary)',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropzoneContent:{
        margin: 0,
        fontSize: '14px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formControlLabel: {
        marginLeft: -8,
    },
}));

export default function UploadFileModal({ open, handleClose, maxFileSize = 5000000, defaultFileType, userId, settings }) {
    const [file, setFile] = React.useState(undefined);
    const [fileType, setFileType] = React.useState(defaultFileType || 'Advice');
    const [errorMessage, setErrorMessage] = React.useState();
    const [shareWithUser, setShareWithUser] = React.useState(!!settings?.shouldShare);
    const styles = useStyles();
    const dispatch = useDispatch();
    const { organisationId } = useParams();

    useEffect(() => {
        setShareWithUser(!!settings?.shouldShare);
    }, [settings?.shouldShare]);

    const handleSave = async () => {
        if(file.size < maxFileSize) {
            file.fileType = fileType;
            const uploadedFile = await dispatch(uploadFile(organisationId, [file], [], fileType, userId, shareWithUser));
            setFile(undefined);
            setFileType('Advice');
            handleClose(uploadedFile);
        } else {
            setErrorMessage('Filen overskrider den maksimale filstørrelse på 5 MB');
        }
    };

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (
        <div>
            <Dialog open={ open } onClose={ () => handleClose() } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><h5>Upload fil</h5></DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ color: '#000000', fontSize: '16px' }}>
                        { shareWithUser ? 'Upload en fil. Filen deles med kunden og kan tilgås i deres Penly under dokumenter.' : 'Upload en fil. Filen deles ikke med kunden medmindre du vælger at dele den.' }
                    </DialogContentText>
                    <div className={ styles.dropzone }  style={{ backgroundColor:'#eeeeee', color:'black',marginTop:20 }}{ ...getRootProps() }>
                        <div>
                            { file ? (
                                <p className={ styles.dropzoneContent }>{ file.name }</p>
                            ) : isDragActive ? (
                                <p className={ styles.dropzoneContent }>Træk en fil hertil for at uploade</p>
                            ) : (
                                <p className={ styles.dropzoneContent }>Træk en fil hertil for at uploade, eller klik for at vælge fil</p>
                            ) }
                        </div>
                    </div>
                    { errorMessage ? <p style={{ color: 'var(--color-error)', paddingTop: '8px' }}>{ errorMessage }</p> : null }
                    <FormControl className={ styles.formControl }>
                        <input { ...getInputProps() } className="pi-uploader-input" />
                        { /* <InputLabel htmlFor="type-native-simple">Type</InputLabel>
                        <Select
                            value={ fileType }
                            disabled={lockFileType}
                            onChange={ handleFileTypeChange }
                            inputProps={{
                                name: 'type',
                                id: 'type-native-simple',
                            }}
                        >
                            <MenuItem disabled selected value="default">Vælg filtype</MenuItem>
                            { fileTypeArr.map(({ key, value }) => (
                                <MenuItem value={ key } key={ key }>
                                    { value }
                                </MenuItem>
                            )) }
                        </Select> */ }
                    </FormControl>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ shareWithUser }
                                    onChange={ (e) => setShareWithUser(e.target.checked) }
                                    name="shareWithUser"
                                    color="primary"
                                />
                            }
                            label="Del med kunden"
                            className={ styles.formControlLabel }
                        />
                    </div>


                    <DialogActions>
                        <Button
                            onClick={ () => {
                                handleClose();
                                setFile(undefined);
                                setFileType('default');
                                setErrorMessage('');
                            } }
                            color="primary" variant="contained">
                            Fortryd
                        </Button>
                        <Button
                            onClick={ handleSave }
                            color="primary"
                            variant="contained"
                            disabled={ !file }>
                            Upload
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}
